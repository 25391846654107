<!--电梯-->
<template>
  <el-dialog :visible="visible" :showClose="false" :modal="false" lock-scroll style="position: absolute">
    <!--    紧急迫降按钮-->
    <div class="liftSwitch" v-if="parameter.liftSwitch">
      <img :src="zoomOutImg" class="zoomOut" @click="onClose" />
      <img :src="liftSwithImg" style="width: 26rem;"/>
      <div class="switch" :class="parameter.switch&&parameter.switch" @click="buttonSelect('els')"></div>
      <div class="reset" @click="buttonSelect('elr')"></div>
    </div>
<!--电梯内部按钮-->
    <div class="liftInnerButton" v-if="parameter.liftInnerButton">
      <img :src="zoomOutImg" class="zoomOut" @click="onClose" />
      <img :src="liftInnerButtonImg" style="width: 18.75rem;"/>
      <div class="nums">
        <span v-for="(item) in liftNums" class="num" :class="parameter.class&&parameter.class['num'+item]" @click="buttonSelect('lb'+item)"> {{item}}</span>
      </div>
      <div class="dial" :class="parameter.class&&parameter.class['dial']" @click="buttonSelect('lbhu')"></div>
      <div class="switch"><span class="s" :class="parameter.class&&parameter.class['switchk']" @click="buttonSelect('lbk')">|</span><span class="s" :class="parameter.class&&parameter.class['switchh']" @click="buttonSelect('lbh')">|</span></div>
      <div class="light"><div class="lift-light" style="line-height: 1; font-size: 3.75rem;font-family: electronicFont">{{parameter.liftNum}}</div></div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    parameter: {},
    close: {
      type: Function,
    },
  },
  data() {
    return {
      zoomOutImg:require('@/assets/img/zoomOut.png'),
      liftSwithImg:require('@/assets/img/wei14/14-1-2-1.png'),
      liftInnerButtonImg:require('@/assets/img/wei14/lift-button.png'),
      closeAudio:'magnifying-xiao.mp3',//弹窗关闭声音
      liftAudio:'wei14/lift-button.mp3',//电梯数字声音
      isShow: false,
      liftNums:[7,6,5,4,3,2,1,-1,-2],
    };
  },
  mounted() {
  },
  created() {
    console.log(this.parameter)
  },

  methods: {
    show() {
      this.isShow = true;
    },
    onClose() {
      this.close && this.close();
      this.$emit('customPlay2', this.closeAudio);
    },
    buttonSelect(value,e) {
      console.log('elevator-buttonSelect',value)
      this.$emit('customOther', value);
      let nums=['lb1','lb2','lb3','lb4','lb5','lb6','lb7','lb-1','lb-2'];
      if(nums.indexOf(value)>-1){
        this.$emit('customPlay2', this.liftAudio);
      }
    },
    arrcover(arr,new_){
      Object.keys(arr).forEach((k)=>{
        if(typeof arr[k] === 'object'){
          if(!new_[k]){
            new_[k]={};
          }
          this.arrcover(arr[k],new_[k])
        }
        else{
          new_[k]=arr[k];
        }
      })
      return new_;
    },
  },
};
</script>

<style lang="less" scoped>
.el-dialog__wrapper {
  position: absolute;
}
.hiden {
  display: none !important;
}
/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  box-shadow: none;
  width: auto;
}
.zoomOut{
  position: absolute;
  right: 1rem;
  top: 1rem;
}
/deep/.el-dialog .el-dialog__body {
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.liftSwitch{
  position: relative;
  .switch{
    position: absolute;
    height: 4.5rem;
    width: 4.5rem;
    border: 0.0625rem solid #8d0000;
    background-color: #8d0000;
    top: 29.187375%;
    left: 40.572104%;
    border-radius: 2.5rem;
    cursor: pointer;
  }
  .reset{
    position: absolute;
    top: 58.093654%;
    left: 40.617021%;
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 4.375rem;
    cursor: pointer;
  }
  .open{
    background-color: red;
    border: 0.0625rem solid red;
    -webkit-box-shadow: 0 0 1.5rem red;
    box-shadow: 0 0 1.5rem red;
  }
}
.liftInnerButton{
  position: relative;
  .nums{
    position: absolute;
    top: 62.582781%;
    left: 26.344086%;
    height: 23.178808%;
    width: 48.207885%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .num{
      width: 1.5rem;
      height: 1.5rem;
      border: 0.125rem solid #dddfe2;
      margin-right: 2.0625rem;
      margin-bottom: 2.375rem;
      text-align: center;
      color: #fff;
      line-height: 1.375rem;
      font-family: DDD;
      font-size: .875rem;
      border-radius: 1.5rem;
    }
    .num:nth-child(3n){
      margin-right: 0;
    }
    .num.selected{
      border: 0.125rem solid red;
      color: red;
    }
  }
  .dial{
    position: absolute;
    top: 53.321%;
    left: 45.978136%;
    height: 4.870199%;
    width: 9.956631%;
    border-radius: 100%;
  }
  .dial.selected{
    border: 0.2rem solid red;
  }
  .switch{
    position: absolute;
    top: 90.442384%;
    left: 37.096774%;
    height: 3.559603%;
    width: 29.032258%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .s{
      width: 1.5rem;
      height: 1.5rem;
      border: 0.125rem solid #dddfe2;
      text-align: center;
      color: #fff;
      line-height: 1.125rem;
      font-size: .625rem;
      border-radius: 1.5rem;
      position: relative;
    }
    .s:first-child::before{
      content: "◀︎";
      position: absolute;
      left: -0.0625rem;
      top: 0.0625rem;
      font-size: 0.75rem;
      scale: 0.8;
    }
    .s:first-child::after{
      content: "▶︎";
      position: absolute;
      right: -0.0625rem;
      top: 0.0625rem;
      font-size: 0.75rem;
      scale: 0.8;
    }
    .s:last-child::before{
      content: "▶";
      position: absolute;
      left: 0;
      top: 0.0625rem;
      font-size: 0.75rem;
      scale: 0.8;
    }
    .s:last-child::after{
      content: "︎◀︎";
      position: absolute;
      right: 0;
      top: 0.0625rem;
      font-size: 0.75rem;
      scale: 0.8;
    }
    .s.selected{
      color: red;
      border: 0.125rem solid red;
    }
  }
  .light{
    position: absolute;
    top: 13.576159%;
    left: 26.344086%;
    height: 16.556291%;
    width: 50%;
  }
}
.lift-light{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  color: red;
  font-family: Digit;
  font-size: 2rem;
  text-align: center;
  line-height: 200%;
}

</style>
