<template>
  <div class="question">
    <div class="main-container">
      <section class="app-main">
        <div class="container" :class="{ container_full:isFullscreen}" ref="container">
          <div class="main-background">
            <img style="width: 100%; height: 100%; object-fit: cover;" :src="branch['bg_img']"/>
          </div>
          <div class="title">
            <span style="font-size: 1.8rem">{{ question["name"] || "-" }}</span>
            <span class="ct">答题倒计时：</span>
            <span>{{ timeLable }}</span>
          </div>

          <!--  -->
          <div class="main">
            <div class="sub-title">
              <span style="font-size: 1.5rem">{{
                  topic[activeKey]["name"]
                }}</span>
              <div class="progress">
                <i class="el-icon-time"></i>
                <span>当前进度:</span>
                <el-progress
                    :percentage="percentage"
                    :color="customColor"
                    style="width: 10rem"
                ></el-progress>
              </div>
            </div>
<!--           选择题-->
            <div class="choose" v-if="branch['choose'] && branch['choose']['title']">
              <div class="choose-title">
                {{ branch["choose"]["title"] }}
              </div>
              <!-- 多选 -->
              <div
                  class="choose-option"
                  v-if="branch['choose']['type'] == 'check'"
              >
                <el-checkbox-group v-model="ckeckList">
                  <div
                      class="option"
                      v-for="(item, index) in branch['choose'][
                      'chooseOption'
                    ]"
                      :key="index"
                  >
                    <el-checkbox :label="item.value">{{
                        item.name
                      }}</el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>

              <!-- 单选 -->
              <div
                  class="choose-option"
                  v-if="branch['choose']['type'] == 'radio'"
              >
                <el-radio-group v-model="radio" @change="submitAnswer(radio)">
                  <div
                      class="option"
                      v-for="(item, index) in branch['choose'][
                      'chooseOption'
                    ]"
                      :key="index"

                  >
                    <el-radio :label="item.value">{{ item.name }}</el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
<!--操作题的题目-->
            <div class="operate" v-if="branch['type'] == 'operate'&&branch['ques_tips']">
                <div class="operate-title">
                  {{ branch["ques_tips"] }}
                </div>
            </div>
          </div>

          <!-- 按钮栏 -->
          <div class="toolbar">
            <div class="toolbar-content">
              <div class="btns">
                <el-button type="primary tips" v-if="type != 'opera'" @click="showTips">提示</el-button>

                <div class="right-btn">

                  <el-button v-if="nextShow" type="primary" :disabled="buttondisable" ref="next" @click="next">下一步</el-button>
                  <el-button type="primary" @click="onSubmit"  :disabled = "subDisabled" >提交</el-button>
                  <el-button type="primary" @click="reset(1)">重做</el-button>
                  <el-button type="primary" @click="goBack">返回</el-button>
                </div>
              </div>
            </div>
          </div>
          <!-- 场景切换 -->
          <div class="navs" v-show="branch['sceneChangeShow']">
            <div class="navs-header">场景导航</div>
            <div class="navs-list">
              <div v-for="(item, index) in topic[activeKey]['scene_change']" :key="index"  :class="item.class" :data-aa="item.class" @click="sceneChange(index)" >
                <img :src="item.img" />
                <span >{{item.name}}</span>
              </div>
            </div>
          </div>
          <!-- 放大镜 -->
          <div
              class="zoom"
              v-for="(item, index) in topic[this.activeKey]['zoomIn']"
              v-show="imgKey===item.scene"
              :style="{
                left: item['left'],
                top: item['top'],
              }"
          >
            <img
                :src="zoomInImg"
                @click="showZoomImg(item.zoom,item.zoom.modalType)"
            />
          </div>

        <!--消控室-->
          <fireController
              v-if="modalType.fireControllerShow"
              :visible="modalType.fireControllerShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.fireControllerShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              ref="fireController"
              class="fireControllerShow"
          ></fireController>
<!--          水泵房一-->

          <waterPump
              v-show="modalType.waterPumpShow"
              :visible="modalType.waterPumpShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.waterPumpShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              class="waterPump"
          ></waterPump>
<!--          工具箱-->
          <tools v-show="modalType.toolboxShow"
                 :visible="modalType.toolboxShow"
                 :branchKey="branchKey"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @subToolAnswer="subToolAnswer"
              @customOther="customOther"
          ></tools>
<!--          图片放大镜-->
          <zoomImg
              v-if="modalType.zoomImgShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.zoomImgShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              ref="ZoomImg"
          ></zoomImg>
          <terminalElectric
              v-if="modalType.terminalElectricShow"
              :visible="modalType.terminalElectricShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.terminalElectricShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              class="terminalElectric"
          >
          </terminalElectric>
          <elevator v-if="modalType.elevatorShow"
             :visible="modalType.elevatorShow"
             :parameter="modalType.modalData"
             :close="() => (modalType.elevatorShow = false)"
             @customPlay="play"
             @customPlay2="play2"
             @customPlay3="play3"
             @customOther="customOther"
             class="elevator"
          ></elevator>
          <!--          提示显示-->
          <div v-if="tipsShow&&branch['tips']" style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: 2009">

            <div class="tips_show">
              <div>
                <div>{{branch['tips']['correctAnswer']}}</div>
                <div>正确操作</div>
                <div v-for="(item, index) in branch['tips']['correctOperation']">{{item.step}}</div>
              </div>
            </div>
          </div>
<!--          电梯门-->
          <div class="lift" v-if="branch['liftDoor']&&imgKey===branch['liftDoor']['liftDoorScene']">
            <div :style="branch['liftDoor']['style1']">
              <div class="lift-door">
                <div class="door-frame" :class="doorFrame" ref="doorFrame">
                  <div id="leftdoor" class="door door-left" :style="{'transition-duration':branch['liftDoor']['tduration']}">
                    <div class="door-0" :style="{'background':'url('+branch['liftDoor']['leftImg']+')'}"></div>
                    <div class="door-2"></div>
                    <div class="door-1" :style="{'background':'url('+branch['liftDoor']['leftImg']+')'}"></div>
                    <div class="door-3"></div>
                  </div>
                  <div class="door door-right" :style="{'transition-duration':branch['liftDoor']['tduration']}">
                    <div class="door-0"></div>
                    <div class="door-2"></div>
                    <div class="door-1" :style="{'background':'url('+branch['liftDoor']['rightImg']+')'}"></div>
                    <div class="door-3"></div>
                  </div>
                </div>
              </div>
            </div>
            <div :style="branch['liftDoor']['style2']">
              <div class="lift-light" :style="branch['liftDoor']['style3']">{{ liftLightNum }}</div>
            </div>
          </div>

          <div class="fullscreen" v-if="!isFullscreen" @click="fullscreenClick">进入全屏</div>
        </div>
      </section>
    </div>
    <!--音频1 题目 -->
    <audio v-show="false" ref="audio" controls src="" preload="metadata" @play="handleMusicPlay" @ended="handleMusicEnded">
      <source src="" />
    </audio>
<!--    音频2 按钮、放大、放小-->
    <audio v-show="false" ref="audio2" controls src="" preload="metadata">
      <source src="" />
    </audio>
<!--    音频3 需要一直循环的声音-->
    <audio v-show="false" ref="audio3" controls src="" preload="metadata">
      <source src="" />
    </audio>
    <Baywindow></Baywindow>
  </div>
</template>

<script>
import zoomImg from "./components/zoomImg.vue";//只显示图片
import fireController from "./components/fireController.vue";//消控室,疏散走道,消防泵控制柜,末端装置试水阀
import tools from "./components/tools.vue";//工具箱
import waterPump from "./components/waterPump.vue";//水泵房一
import terminalElectric from "./components/terminalElectric.vue";//末端配电装置
import elevator from "./components/elevator.vue";//电梯
import {arraysAreEqual, fomatTime} from "../../util/arr.js";
import screenfull from "screenfull";
import Baywindow from "@/components/Baywindow.vue";

// 策略模式
let modalType = {
  'zoomImg': function (data) {
    this.zoomImgShow = true
    this.modalData = data;
  },
  'fireController': function (data) {
    this.fireControllerShow = true
    this.modalData = data;
  },
  'waterPump': function (data) {
    this.waterPumpShow = true
    this.modalData = data;
  },
  terminalElectric: function (data){
    this.terminalElectricShow = true
    this.modalData = data;
  },
  elevator:function (data){
    this.elevatorShow = true
    this.modalData = data;
  },
  fireControllerShow: false,
  waterPumpShow: false,
  zoomImgShow: false,
  toolboxShow:false,
  terminalElectricShow:false,
  elevatorShow:false,
  modalData: {},
}

export default {
  data() {
    return {
      zoomInImg:require('../../assets/img/zoomIn.png'),
      isFullscreen: false,
      question: null,//所有数据
      topic: [],//当前题目
      branch:[],//当前题目的第几小题
      // 进度条
      url:'url("https://bankaoedu.com/files/xc/closed.png") no-repeat',
      per_:20,
      percentage: 20,
      customColor: "#409eff",
      customColors: [{ color: "#f56c6c", percentage: 20 }],
      cities: [],
      ckeckList: [],
      radio: null,
      activeKey: 0,//当前题目的索引
      nextShow:false,//点击下一步出现的小提示
      branchKey: 0,//点击下一步, 当前题目的第几小题索引
      imgKey: 0,//场景的第几张图
      buttondisable:false,//提交按钮, 是否点击
      tipsShow:false,//提示的显示
      submitAns:[],//每小题 提交的答案
      subDisabled:false,//提交按钮, 是否点击
      // 答案集合
      modalType,
      ansObj: {},
      init_question:{},
      doorFrame:'',
      liftLightNum:4,
      type:'viewr',
      count:0,
      timer:null,
      timeLable:'00:00',
      apiId:'100014',
      totalScore:0,
    };
  },
  components: {
    zoomImg,
    fireController,
    tools,
    waterPump,
    terminalElectric,
    elevator,
    Baywindow
  },
  created() {
    let { question } = require(`../../api/wei100014`);
    this.init_question=this.deepClone(question);
    this.question = this.deepClone(question);
    this.topic = this.question["topic"];//所有题目
    this.branch=this.topic[this.activeKey]['branch'][this.branchKey]//当前第几题的第几小题
    if(this.branch['liftDoor']&&this.branch['liftDoor']['closeOpen']){
      this.doorFrame=this.branch['liftDoor']['closeOpen'];
      this.liftLightNum=this.branch['liftDoor']['liftLightNum'];
    }
    this.nextQuestion();

    if(this.topic[this.activeKey]['ques_tips']&&this.topic[this.activeKey]['ques_tips'].length>1){
      this.nextShow=true;
    }
    screenfull.toggle();
    this.per_=100/this.topic.length;
    this.percentage=Math.ceil(this.per_*(this.activeKey+1));//进程
    this.modalClose();
    this.type = this.$route.query.type
    this.count = this.$route.query.count
    if(this.type !== 'viewr'){
      this.timeCountSetInterVal()
    }
  },
  mounted() {
    // 监听全屏变化
    screenfull.onchange(() => {
      this.isFullscreen = screenfull.isFullscreen;
    })
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    this.timer=null;
    next(true);
  },
  methods: {
    fullscreenClick(){// 全屏
      screenfull.toggle();
    },
    timeCountSetInterVal(){    // 考试模式计时器
      if(this.timer) return
      this.timer = setInterval(() => {
        this.count = this.count - 1
        this.timeLable=fomatTime(this.count)
        if(this.count <= 0){
          clearInterval(this.timer)
          this.timer = null

          let ids = sessionStorage.getItem('wids') ? JSON.parse(sessionStorage.getItem('wids')) : []
          ids.push(this.apiId)
          sessionStorage.setItem('wids',JSON.stringify(ids))
          window.sessionStorage.setItem("topic", JSON.stringify(this.topic));
          window.sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));
          this.$router.replace("/result");
          this.reset();
        }
      },1000)
    },
    deepClone (source){
      var sourceCopy = source instanceof Array ? [] : {}
      for (var item in source) {
        sourceCopy[item] = typeof source[item] === 'object' ? this.deepClone(source[item]) : source[item]
      }
      return sourceCopy
    },
    play(val) {
      //题目
      if (!val)  return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio.src = music;
        let { audio } = this.$refs;
        audio.load();
        audio.play();
      });
    },
    play2(val) {
      //一些按钮声音
      if (!val)  return;
      this.$refs.audio2.pause();
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);
      this.$nextTick((res) => {
        this.$refs.audio2.src = music;
        let audio = this.$refs.audio2;
        audio.load();
        audio.play();
      });
    },
    play3(val,loop=false,play='play') {
      //可循环
      if(play==='stop'&&this.$refs.audio3){
        this.$refs.audio3.pause()
      }
      if (!val)  return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);
      this.$nextTick((res) => {
        this.$refs.audio3.src = music;
        let audio = this.$refs.audio3;
        audio.loop=loop;
        audio.load();
        audio.play();
      });
    },
    goBack() {
      this.$router.go(-1);
      this.play2('button.mp3');
    },
    async onSubmit() {
      if(this.subDisabled){
        return false;
      }
      let ans = this.question.topic[this.activeKey]["ans"];//
      // console.log('小题提交数据',ans, this.submitAns[this.activeKey])

      this.ansObj[this.activeKey] = arraysAreEqual(ans, this.submitAns[this.activeKey])
      // console.log('小题提交结果',this.ansObj[this.activeKey],ans, this.submitAns[this.activeKey])

      if(this.ansObj[this.activeKey]){
        this.totalScore+=parseFloat(this.topic[this.activeKey]['score'])
      }
      let sorceObj = sessionStorage.getItem("sorceObj")? JSON.parse(sessionStorage.getItem("sorceObj")):[];
      sorceObj[this.apiId] = this.totalScore
      sessionStorage.setItem("sorceObj",JSON.stringify(sorceObj));
      console.log('分数',sorceObj)

      if (this.activeKey < this.question.topic.length - 1) {
        this.activeKey = this.activeKey + 1;//下一题
        if(this.topic[this.activeKey]['branch']&&this.topic[this.activeKey]['branch'][this.branchKey+1]){
          this.nextShow=true;
        }
        else{
          this.nextShow=false;
        }
      }
      else {
        this.play2('select.mp3');
        this.play2('select.mp3');

        let ids = sessionStorage.getItem('wids') ? JSON.parse(sessionStorage.getItem('wids')) : []
        ids.push(this.apiId)
        sessionStorage.setItem('wids',JSON.stringify(ids))
        window.sessionStorage.setItem("topic", JSON.stringify(this.topic));
        window.sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));
        // console.log('做完啦',JSON.stringify(this.ansObj))
        setTimeout(() => {
          this.$router.replace("/result");
        }, 500);
        return true;
      }

      this.reset();
    },
    reset(type=0) {
      //重做
      //答案清空
      this.ckeckList = [];
      this.radio = null;
      this.submitAns=[];
      //索引情况
      this.branchKey=0;
      //按钮清除
      this.modalClose();

      //场景图索引
      this.imgKey=0;
      //
      this.nextQuestion();
      this.play2('select.mp3');
      this.play3('','','stop');
      //重置需要清除改变的参数
      if(type===1){
        this.topic = this.deepClone(this.init_question["topic"]);
        this.branch=this.topic[this.activeKey]['branch'][this.branchKey]//当前第几题的第几小题
      }
      if(this.branch['liftDoor']&&this.branch['liftDoor']['closeOpen']){
        this.doorFrame=this.branch['liftDoor']['closeOpen'];
        this.liftLightNum=this.branch['liftDoor']['liftLightNum'];
      }
    },

    // ===================
    // // 全面屏
    fullscreen() {
      // 需要全屏显示的dom元素
      let dom = this.$el.querySelector(".container");
      // 调用全屏方法
      this.$fullscreen.enter(dom, {
        wrap: false,
        callback: (f) => {
          this.fullscreenFlag = f;
        },
      });
    },
    submitAnswer(value){
      //每小题选择的答案
      if(!this.submitAns[this.activeKey]){
        this.submitAns[this.activeKey]=[];
      }
      this.submitAns[this.activeKey][0]=value;
      this.play2('select.mp3');
    },
    handleMusicPlay(){
      if(this.nextShow){
        this.subDisabled = true;
      }
    },
    handleMusicEnded() {
      this.buttondisable=false;
      if(this.topic[this.activeKey]['branch'].length===1){
        this.subDisabled=false;
      }
    },
    next(event){
      //点击下一步, 下一题
      if(this.buttondisable){
        return false;
      }
      if(this.topic[this.activeKey]['branch'][this.branchKey+1]){
        this.branchKey++;
        this.branch=this.topic[this.activeKey]['branch'][this.branchKey];
        this.nextShow=true;
        if(!this.submitAns[this.activeKey]){
          this.submitAns[this.activeKey]=[];
        }
        if(!this.submitAns[this.activeKey][this.branchKey]){
          this.submitAns[this.activeKey][this.branchKey]=0;
        }

        //有场景切换, 背景图不变
        let bg_=this.topic[this.activeKey]['scene_change'][this.imgKey]['bg']
        this.branch['bg_img']=bg_;
      }
      else{
        if(this.topic[this.activeKey]['branch'][this.branchKey+2]){
        }
        else{
          this.nextShow=false
        }
        this.subDisabled=false;
      }
      //如果有选择框,删除选择框
      var box_frame_bg=document.querySelectorAll('.box_frame_bg');
      for(let i=0;i<box_frame_bg.length;i++){
        box_frame_bg[i].classList.remove('box_frame_bg')
      }

      this.modalClose();
      this.play2('button.mp3')

    },
    nextQuestion(){
      //提交后的 ,下一题的处理,如题目播放, 是否随机题目
      this.percentage=(this.per_*(this.activeKey+1));//进程
      this.branchKey=0;
      this.imgKey=0;
      this.branch=this.topic[this.activeKey]['branch'][this.branchKey];
      this.play(this.topic[this.activeKey]["audio"])
      if(this.branch['audio']){
        this.play3(this.branch['audio'],true)
      }

      if(this.topic[this.activeKey]['branch'][this.branchKey+1]) {
        this.nextShow = true;
      }
      if(this.topic[this.activeKey]['defautans']){
        this.submitAns[this.activeKey]=this.topic[this.activeKey]['defautans'];
      }
      //如果有场景切换
      if(this.branch['sceneChangeShow']){
        this.sceneChange(this.imgKey);
      }

    },
    modalClose(){
      //关闭所有弹窗和提示
      this.tipsShow=false;//提示
      this.modalType.fireControllerShow=false;//消控室
      this.modalType.waterPumpShow=false;//水泵房
      this.modalType.zoomImgShow=false;//图片
      this.modalType.toolboxShow=false;//工具箱
      this.modalType.terminalElectricShow=false;//末端配电装置
      this.modalType.elevatorShow=false;//电梯相关
    },
    showTips(){//显示提示
      if(this.tipsShow===false){
        this.tipsShow=true;
      }
      else{
        this.tipsShow=false;
      }
      this.play2('button.mp3');
    },
    sceneChange(index_){
      //场景小图切换
      this.imgKey=index_;
      let scene_change=this.topic[this.activeKey]['scene_change'][index_];
      this.branch ['bg_img']=scene_change['bg']

      if(document.querySelector('.nav.actived')){
        document.querySelector('.nav.actived').classList.remove('actived');
      }
      if(document.querySelectorAll('.nav')[index_]){
        document.querySelectorAll('.nav')[index_].classList.add('actived')
      }
      var box_frame_bg=document.querySelectorAll('.box_frame_bg');
      if(box_frame_bg){
        for(let i=0;i<box_frame_bg.length;i++){
          box_frame_bg[i].classList.remove('box_frame_bg')
        }
      }

      //如果有工具箱出现
      if(scene_change.toolboxShow){
        this.modalType.toolboxShow=true;
      }
      else{
        this.modalType.toolboxShow=false;
      }
      //如果有声音
      if(scene_change.audio){
        this.play3(scene_change.audio,true);
      }
      else{
        this.play3('',false,'stop');
      }
    },
    operateAnswer(val,e){
      //操作题的答案,
    },
    showZoomImg(zoom,type='img'){
      //点击放大镜
      zoom.branchKey=this.branchKey;
      this.modalType[zoom['modalType']](zoom)
      this.play2('magnifier-da.mp3');
    },
    subAnswer(value){
      //子组件, 提交答案
      let branchKey=value[0];//
      let val=value[1];
      if(!this.submitAns[this.activeKey]){
        this.submitAns[this.activeKey]=[];
      }
      if(!this.submitAns[this.activeKey][branchKey]){
        this.submitAns[this.activeKey][branchKey]=0;
      }
      this.submitAns[this.activeKey][branchKey]=val;

    },
    subToolAnswer(value,branch=-1,next=0){
      //工具箱提交答案,不同的题处理不一样
      // console.log('subToolAnswer',value,branch,this.branchKey,next)
      let this_=this;
    },
    customOther(value){
      //子组件 , 不同 操作的显示参数修改,对应答案, 不同的题处理不同
      // console.log('customOther',this.activeKey,this.branchKey,value)
      let this_=this;
      if(this.activeKey===0){
        if(value==='els'){
          //电梯pojiang
          this.branch.liftDoor.tduration='3s';
          this.modalType.modalData['switch']='open';
          let url=this.modalType.modalData['audio1'];//运行
          this.play2(url);
          //电梯下至一楼
          for (let i=4;i>=1;i--){
            var time=(4-i)*1000;
            setTimeout(function () {
             this_.liftLightNum=i;
             if(i===1){
               let ur2=this_.modalType.modalData['audio2'];//打开
               this_.doorFrame='open';
               this_.play2(ur2);
             }
            },time)
          }
          //消控室  启动反馈, 信息
          this.topic[this.activeKey]['zoomIn'][1]['zoom']['print']=true;
          this.topic[this.activeKey]['zoomIn'][1]['zoom']['light']['start']='red';
          this.topic[this.activeKey]['zoomIn'][1]['zoom']['light']['feedback']='red';
          this.topic[this.activeKey]['zoomIn'][1]['zoom']['f6']['show']=true;
          this.topic[this.activeKey]['zoomIn'][1]['zoom']['f2']['show']=true;
          this.topic[this.activeKey]['zoomIn'][1]['zoom']['defaultKey']='f2';

        }
      }
      else if(this.activeKey===1){
        if(value==='lb4'){
          this.modalType.modalData.class.num4='selected';
          this.modalType.modalData.class.switchh='';
          this.modalType.modalData.class.switchk='';
          if(this_.branchKey===0){
            this_.submitAns[this_.activeKey][0]=1;
            this_.branchKey=1;
          }
        }
        else if(value==='lbh'){
          //选中按钮
          this.modalType.modalData.class.num4='';
          this.modalType.modalData.class.switchh='selected';
          this.modalType.modalData.class.switchk='';
          //关门
          this.branch.liftDoor.tduration='3s';
          this.doorFrame='close';
          let url1=this.modalType.modalData.audio1;
          this.play2(url1)
          if(this_.branchKey===1){
            //电梯数上升到4
            for (let i=1;i<=4;i++){
              var time=(i+2)*1000;
              if(i===1){
                let url2=this.modalType.modalData.audio3;
                this_.play3(url2)
                this_.modalType.modalData.class.switchh='';
              }
              setTimeout(function () {
                this_.liftLightNum=i;
                this_.modalType.modalData.liftNum=i;
              },time)
            }
            this_.branchKey=2;
            this_.submitAns[this_.activeKey][1]=2;
          }
        }
        else if(value==='lbk'&&this_.modalType.modalData.liftNum===4){
          //选中按钮
          this.modalType.modalData.class.switchh='';
          this.modalType.modalData.class.lb4='';
          this.modalType.modalData.class.switchk='selected';
          //开门
          this.doorFrame='open';
          let url1=this.modalType.modalData.audio2;
          this.play2(url1)
          if(this.branchKey===2){
            this_.submitAns[this_.activeKey][2]=3;
          }
        }
      }
      else if(this.activeKey===2){//
        if(value==='lbhu'){
          this_.modalType.modalData.class.dial='selected';
          let url=this_.modalType.modalData.audio2;
          this_.play3(url);
          this_.submitAns[this_.activeKey][0]=1;
        }
      }
      else if(this.activeKey===3){
        if(value==='bopian'){
          if(this_.branchKey===0){
          this.modalType.modalData.class.bopian='bopian';
          let url=this_.modalType.modalData.bopianAudio;
          this_.play2(url);
            this_.submitAns[this_.activeKey][0]=1;
            this_.branchKey=1;
          }
        }
        else if(value==='son'){

          if(this_.branchKey===1){
          this.modalType.modalData.switchOn=false;
          let url=this_.modalType.modalData.audio1;
          // this_.play2(url);

          this.topic[this.activeKey]['zoomIn'][0]['zoom']['class']['zhudian']='';
          this.topic[this.activeKey]['zoomIn'][0]['zoom']['class']['zdhezha']='';
          this.topic[this.activeKey]['zoomIn'][0]['zoom']['class']['beidian']='green';
          this.topic[this.activeKey]['zoomIn'][0]['zoom']['class']['bdhezha']='red';

          this_.modalType.modalData.class.zhudian='white';
          this_.modalType.modalData.class.zdhezha='white';
          this_.modalType.modalData.class.beidian='red';
          this_.modalType.modalData.class.bdhezha='red';
          setTimeout(function () {
            this_.modalType.modalData.class.rotate='rotate-beiyong';
            this_.play2(url);
          },300)
            this_.submitAns[this_.activeKey][1]=2;
            this_.branchKey=2;
          }
        }
      }
      else if(this.activeKey===4){
        if(value==='elr'&&this.branchKey===0){
          this_.modalType.modalData.switch='';
          this_.submitAns[this.activeKey][0]=1;
          this.branchKey=1;
        }
        else if(value==='reset'&&this.branchKey===1){
          this.submitAns[this.activeKey][1]=2;
        }
      }
    }
  },
};
</script>
<style>
.el-radio__label{
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
<style lang="less" scoped>
.question {
  position: relative;
  height: 100%;
  width: 100%;
  // ======
}

.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: 0;
  position: relative;
  background: #f8f8f8;
}
.app-main {
  position: relative;
}
.container_full{ // 全屏下
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 auto !important;
  position: relative !important;
}
.container {
  // 非全屏下
  width: 120rem;
  height: 67.5rem;
  margin: 0 auto;
  position: relative;
  padding-bottom: 20px;
  .main-background {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // width: 100%;
    // height: 100%;
  }
  .title {
    height: 2.5rem;
    background-color: #409eff;
    line-height: 2.5rem;
    color: #fff;
    font-weight: 500;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 8;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 2rem;
    .ct {
      margin-left: auto;
    }
  }
  .main {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-attachment: fixed;
    .sub-title {
      height: 2.5rem;
      background-color: #fff;
      padding: 0 1.25rem;
      position: absolute;
      z-index: 8;
      left: 0;
      top: 2.5rem;
      width: 100%;
      font-size: 1.5rem;
      .progress {
        font-size: 0.875rem;
        span {
          font-size: 0.8125rem;
          margin: 0 0.3125rem;
        }
      }
    }
    // 选这题
    .choose {
      position: absolute;
      left: 1.25rem;
      top: 6.25rem;
      min-width: 12.5rem;
      max-width: 21.875rem;
      background-color: #409eff;
      z-index: 99999;
      border-radius: 0.625rem;
      box-shadow: 1px 1px 5px #ccc;
      box-sizing: border-box;
      .choose-title {
        color: #fff;
        padding: 0.625rem;
        font-size: 1.5rem;
      }
      .choose-option {
        background-color: #fff;
        padding: 1.25rem 0.625rem;
        border-radius: 0 0 0.625rem 0.625rem;
        .option {
          margin-bottom: 1.25rem;
          font-size: 1.5rem;
        }
      }
    }

    .operate {
      position: absolute;
      left: 1.25rem;
      top: 6.25rem;
      min-width: 12.5rem;
      max-width: 21.875rem;
      background-color: #409eff;
      z-index: 99999;
      border-radius: 0.625rem;
      box-shadow: 1px 1px 5px #ccc;
      box-sizing: border-box;
      .operate-title {
        color: #fff;
        padding: 0.625rem;
        font-size: 1.5rem;
      }
      .choose-option {
        background-color: #fff;
        padding: 1.25rem 0.625rem;
        border-radius: 0 0 0.625rem 0.625rem;
        .option {
          margin-bottom: 1.25rem;
          font-size: 1.5rem;
        }
      }
    }

  }

  //   按钮
  .toolbar {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 2.5rem;
    z-index: 9999;
  }
  .toolbar-content {
    margin: 0 12.5rem;
    .right-btn {
      margin-left: auto;
    }
    button {
      padding: 0.625rem 1.875rem;
      color: #fff;
      font-size: 16px;
      margin: 0 1.875rem;
      border-radius: 0.3125rem;
      cursor: pointer;
    }
  }
  .toolbar-content .btns,
  .toolbar-content .right-btn {
    display: flex;
    align-items: center;
  }
  .toolbar-content .btns {
    position: absolute;
    z-index: 9999;
    left: 6.25rem;
    right: 6.25rem;
    bottom: 0;
  }

  .navs {
    position: absolute;
    right: 2.5rem;
    bottom: 10rem;
    z-index: 334;
    .navs-header {
      background-color: #409eff;
      border-radius: 0.625rem 0.625rem 0 0;
      color: #fff;
      font-size: 1rem;
      padding: 0.625rem 0.9375rem;
    }
    .navs-list {
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0.9375rem 0.9375rem;
      border-radius: 0 0 0.625rem 0.625rem;
      //height: 9.375rem;
      //overflow-y: auto;
      .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 13.125rem;
        margin-bottom: 0.9375rem;
        cursor: pointer;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #fff;
        img {
          width: 5rem;
          height: 3.125rem;
          -o-object-fit: cover;
          object-fit: cover;
          border-radius: 0.3125rem 0 0 0.3125rem;
        }
        span {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          text-align: center;
        }
      }
      .actived {
        border: 1px solid #409eff;
        color: #409eff;
        border-radius: 0.3125rem;
        font-weight: 500;
      }
      .nav:last-child {
        margin-bottom: 0;
      }
    }
  }
  //可以选择的框
  .box_frame{
    border:2px solid blue;
    position: absolute;
  }
  .box_frame_bg{
    background: #6b82f5;
    opacity: 0.7;
  }
  //提示显示
  .tips_show{
    z-index: 2009;
    border-radius: 0.3125rem;
    border: 0.3125rem solid #ccc;
    position: absolute;
    background: #fdfdfd;
    width: 50%;
    top: 20%;
    left: 25%;
    padding: 1rem;
    font-size:1.15rem ;
    div{
      line-height: 35px ;
      //text-indent: 1rem;
    }
  }
  .toolboxs{
    position: absolute;
    top: 20rem;
    right: 1rem;

    .toolbox {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 18rem;
      background-color: #fff;
      border-radius: .625rem;
    }
    .toolbox-title {
      background-color: #409eff;
      color: #fff;
      font-size: 1.125rem;
      padding: .6rem 0.4rem;
      border-radius: .625rem .625rem 0 0;
    }
    .toolbox-tools {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: .625rem 1rem;
      height: 18rem;
      overflow: auto;
      /* scrollbar-width: none; */
      -ms-overflow-style: none;
    }
    .toolbox-tool {
      width: 29%;
      height: 7rem;
      font-size: .75rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-right: .5rem;
      margin-bottom: .625rem;
      border: 1px solid rgba(64,158,255,.8);
      border-radius: .1875rem;
    }
    .toolbox-tool-bg{
      background: #cae1ffc6;
    }
    .toolbox-tool img {
      width: 3.75rem;
      margin: .5rem 0;
    }
    .toolbox-vertical-scroll {
      position: absolute;
      right: 0;
      width: .5rem;
      top: 2.5rem;
      -webkit-transition: all .3s ease-out;
      transition: all .3s ease-out;
      visibility: visible;
      background-color: #fff;
      height: 15.625rem;
      overflow: hidden;
    }
    .toolbox-confirm{
      text-align: center;
      padding: 5px;
    }
  }
  .fullscreen {
    position: absolute;
    bottom: -3.125rem;
  }
}

.container .main .sub-title .progress,
.container .main .sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.zoom {
  position: absolute;
  cursor: pointer;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
}
/deep/.zoomOut{
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
}
/deep/.el-dialog__wrapper{
  z-index: 2000 !important;
}
.smoke-tool{
  position: absolute;
  right: 45.6%;
  top: 16%;
  width: 12rem;
  display: none;
}
.smoke-tool-block{
  display: block;
}
.lift-door{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  div{
    position: absolute;
  }
  .door-frame{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0 solid #333;
    border-bottom: 0;
    -webkit-perspective: 75rem;
    perspective: 75rem;
  }
  .door{
    width: 50%;
    top: 0;
    bottom: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0;
    transition-duration: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .open .door-left{
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .door-left{
    left: 0;
  }
  .door-0{
    -webkit-transform: translateZ(-0.75rem);
    transform: translateZ(-0.75rem);
  }
  .door-0,.door-1{
    left: -0.1875rem;
    top: -0.1875rem;
    right: -0.1875rem;
    bottom: -0.1875rem;
    border: 0 solid #333;
    background-color: #333;
    //background: (var(--liftleftdoor));
    //background-image: (var(--liftleftdoor));
    //background-image: url(../../assets/img/wei14/left-door.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .door-2{
    height: 0.375rem;
    left: -0.1875rem;
    right: -0.1875rem;
    top: 0;
    border: 0.1875rem solid #1a0a00;
    border-radius: 0.125rem;
    background-color: #2b1201;
    -webkit-transform: translate3D(0, -0.4688rem, -0.375rem) rotateX(90deg);
    transform: translate3D(0, -0.4688rem, -0.375rem) rotateX(90deg);
  }
  .door-1{
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .door-3{
    width: 0.375rem;
    top: -0.1875rem;
    right: 0;
    bottom: -0.1875rem;
    border: 0.1875rem solid #1a0a00;
    border-radius: 0.125rem;
    background-color: #2b1201;
    -webkit-transform: translate3D(0.4688rem, 0, -0.375rem) rotateY(90deg);
    transform: translate3D(0.4688rem, 0, -0.375rem) rotateY(90deg);
  }
  .open .door-right{
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .door-right{
    right: 0;
  }
  .door-right .door-1{
    //background-image: var(--liftrightdoor);
    //background-image: url(../../assets/img/wei14/right-door.png);
  }
}
.lift-light{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  color: red;
  font-family: electronicFont;
  font-size: 2rem;
  text-align: center;
  line-height: 200%;
}
</style>
